import { BigNumber } from '@ethersproject/bignumber'
import { Contract } from '@ethersproject/contracts'
import { JSBI, Percent, Router, SwapParameters, Trade, TradeType } from '@arborswap/sdk'
import { useMemo } from 'react'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import { useGasPrice } from 'state/user/hooks'
import { formatPortalValue } from 'utils/bigNumber'
import { PortalTx } from 'state/portal/reducer'
import { BIPS_BASE, INITIAL_ALLOWED_SLIPPAGE } from '../config/constants'
import { useTransactionAdder } from '../state/transactions/hooks'
import {
  calculateGasMargin,
  getForeignPortalContract,
  getManagementContract,
  isAddress,
  shortenAddress,
} from '../utils'
import isZero from '../utils/isZero'
import useTransactionDeadline from './useTransactionDeadline'
import useENS from './ENS/useENS'
import { PortalRelayInfo } from './useRelayPortal'

export enum PortalCallbackState {
  INVALID,
  LOADING,
  VALID,
}

interface PortalCall {
  contract: Contract
  parameters: SwapParameters
}

interface SuccessfulCall {
  call: PortalCall
  gasEstimate: BigNumber
}

interface FailedCall {
  call: PortalCall
  error: Error
}

type EstimatedSwapCall = SuccessfulCall | FailedCall

function usePortalCallArguments(
  portaltx: PortalTx | undefined, // trade to execute, required
): PortalCall[] {
  const { account, chainId, library } = useActiveWeb3React()
  const deadline = useTransactionDeadline()

  return useMemo(() => {
    if (!portaltx || !library || !account || !chainId || !deadline) return []
    if (!portaltx.tokenIn || !portaltx.ammount) return []

    const contract: Contract | null = getForeignPortalContract(chainId, library, account)
    if (!contract) {
      return []
    }

    const bridgeAmount = formatPortalValue(portaltx)

    const portalMethod = []
    portalMethod.push({
      methodName: 'relayToken',
      args: [portaltx.tokenIn.address, portaltx.addressTo, bridgeAmount, portaltx.withGas ? 'true' : 'false'],
      value: 0,
    })

    return portalMethod.map((parameters) => ({ parameters, contract }))
  }, [account, chainId, deadline, library, portaltx])
}

// returns a function that will execute a swap, if the parameters are all valid
// and the user has approved the slippage adjusted input amount for the trade
export function usePortalCallback(
  portaltx: PortalTx | undefined,
  portalInfo: PortalRelayInfo, // trade to execute, required
): { state: PortalCallbackState; callback: null | (() => Promise<string>); error: string | null } {
  const { account, chainId, library } = useActiveWeb3React()
  const gasPrice = useGasPrice()
  const addTransaction = useTransactionAdder()

  const { address: recipientAddress } = useENS(portaltx?.addressTo)
  const recipient = portaltx?.addressTo ? recipientAddress : account
  const portalCalls = usePortalCallArguments(portaltx)

  return useMemo(() => {
    if (!portaltx || !library || !account || !chainId) {
      return { state: PortalCallbackState.INVALID, callback: null, error: 'Missing dependencies' }
    }
    if (!recipient) {
      return { state: PortalCallbackState.LOADING, callback: null, error: null }
    }

    return {
      state: PortalCallbackState.VALID,
      callback: async function onSwap(): Promise<string> {
        const estimatedCalls: EstimatedSwapCall[] = await Promise.all(
          portalCalls.map((call) => {
            const {
              parameters: { methodName, args, value },
              contract,
            } = call
            const options = !value || isZero(value) ? {} : { value }

            return contract.estimateGas[methodName](...args, options)
              .then((gasEstimate) => {
                return {
                  call,
                  gasEstimate,
                }
              })
              .catch((gasError) => {
                console.error('Gas estimate failed, trying eth_call to extract error', call)

                return contract.callStatic[methodName](...args, options)
                  .then((result) => {
                    console.error('Unexpected successful call after failed estimate gas', call, gasError, result)
                    return { call, error: new Error('Unexpected issue with estimating the gas. Please try again.') }
                  })
                  .catch((callError) => {
                    console.error('Call threw error', call, callError)
                    const reason: string = callError.reason || callError.data?.message || callError.message
                    const errorMessage = `The transaction cannot succeed due to error: ${
                      reason ?? 'Unknown error, check the logs'
                    }.`

                    return { call, error: new Error(errorMessage) }
                  })
              })
          }),
        )

        // a successful estimation is a bignumber gas estimate and the next call is also a bignumber gas estimate
        const successfulEstimation = estimatedCalls.find(
          (el, ix, list): el is SuccessfulCall =>
            'gasEstimate' in el && (ix === list.length - 1 || 'gasEstimate' in list[ix + 1]),
        )

        if (!successfulEstimation) {
          const errorCalls = estimatedCalls.filter((call): call is FailedCall => 'error' in call)
          if (errorCalls.length > 0) throw errorCalls[errorCalls.length - 1].error
          throw new Error('Unexpected error. Please contact support: none of the calls threw an error')
        }

        const {
          call: {
            contract,
            parameters: { methodName, args, value },
          },
          gasEstimate,
        } = successfulEstimation

        return contract[methodName](...args, {
          gasLimit: calculateGasMargin(gasEstimate),
          gasPrice,
          ...(value && !isZero(value) ? { value, from: account } : { from: account }),
        })
          .then((response: any) => {
            const inputSymbol = portaltx.tokenIn.symbol
            const outputSymbol = portaltx.tokenIn.symbol
            const inputAmount = portaltx.ammount.toSignificant(3)
            const outputAmount = portalInfo.receiveAmount.toSignificant(3)

            const base = `Bridge ${inputAmount} ${inputSymbol} for ${outputAmount} ${outputSymbol}`
            const withRecipient =
              recipient === account
                ? base
                : `${base} to ${recipient && isAddress(recipient) ? shortenAddress(recipient) : recipient}`

            addTransaction(response, {
              summary: withRecipient,
            })

            return response.hash
          })
          .catch((error: any) => {
            // if the user rejected the tx, pass this along
            if (error?.code === 4001) {
              throw new Error('Transaction rejected.')
            } else {
              // otherwise, the error was unexpected and we need to convey that
              console.error(`Swap failed`, error, methodName, args, value)
              throw new Error(`Swap failed: ${error.message}`)
            }
          })
      },
      error: null,
    }
  }, [portaltx, library, account, chainId, recipient, portalCalls, addTransaction, portalInfo, gasPrice])
}
