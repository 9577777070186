import React from 'react'
import styled from 'styled-components'
import BigNumber from 'bignumber.js'
import dayjs from 'dayjs'
import { Button, useModal, IconButton, AddIcon, MinusIcon, Skeleton, useTooltip, Flex, Text } from '@arborswap/uikit'
import ConnectWalletButton from 'components/ConnectWalletButton'
import { useWeb3React } from '@web3-react/core'
import { Pool } from 'state/types'
import Balance from 'components/Balance'
import { useTranslation } from 'contexts/Localization'
import { getBalanceNumber } from 'utils/formatBalance'
import { PoolCategory } from 'config/constants/types'
import { getNativeName } from 'utils/getNativeName'
import { BIG_ZERO } from 'utils/bigNumber'
import { getAddress } from 'utils/addressHelpers'
import { useERC20 } from 'hooks/useContract'
import { ActionContainer, ActionTitles, ActionContent } from './styles'
import NotEnoughTokensModal from '../../PoolCard/Modals/NotEnoughTokensModal'
import StakeModal from '../../PoolCard/Modals/StakeModal'
import { useApprovePool } from '../../../hooks/useApprove'

const IconButtonWrapper = styled.div`
  display: flex;
`

interface StackedActionProps {
  pool: Pool
  userDataLoaded: boolean
}

const Staked: React.FunctionComponent<StackedActionProps> = ({ pool, userDataLoaded }) => {
  const {
    sousId,
    stakingToken,
    earningToken,
    isFinished,
    isDisabled,
    poolCategory,
    userData,
    stakingTokenPrice,
    isAutoVault,
    isMembership,
  } = pool
  const { t } = useTranslation()
  const { account, chainId } = useWeb3React()

  const stakingTokenContract = useERC20(stakingToken.address ? getAddress(chainId, stakingToken.address) : '')

  const { handleApprove: handlePoolApprove, requestedApproval: requestedPoolApproval } = useApprovePool(
    stakingTokenContract,
    sousId,
    earningToken.symbol,
  )

  const handleApprove = handlePoolApprove

  const handleBuyNFT = () => {
    window.open(stakingToken.projectLink, '_blank')
  }

  const requestedApproval = requestedPoolApproval

  const isNativePool = poolCategory === PoolCategory.NATIVECOIN
  const allowance = userData?.allowance ? new BigNumber(userData.allowance) : BIG_ZERO
  const nftBalance = userData?.nftBalance ? new BigNumber(userData.nftBalance) : BIG_ZERO
  const stakedBalance = userData?.stakedBalance ? new BigNumber(userData.stakedBalance) : BIG_ZERO
  const isNotVaultAndHasStake = !isAutoVault && stakedBalance.gt(0)

  const stakingTokenBalance = userData?.stakingTokenBalance ? new BigNumber(userData.stakingTokenBalance) : BIG_ZERO

  const stakedTokenBalance = getBalanceNumber(stakedBalance, stakingToken.decimals)
  const stakedTokenDollarBalance = getBalanceNumber(
    stakedBalance.multipliedBy(stakingTokenPrice),
    stakingToken.decimals,
  )

  const needsApproval = !allowance.gt(0) && !isNativePool

  const needBuyNFT = !nftBalance.gt(0)

  // console.log({ userData })
  const [onPresentTokenRequired] = useModal(<NotEnoughTokensModal tokenSymbol={stakingToken.symbol} />)
  const [onPresentStake] = useModal(
    <StakeModal
      isNativePool={isNativePool}
      pool={pool}
      stakingTokenBalance={stakingTokenBalance}
      stakingTokenPrice={stakingTokenPrice}
    />,
  )

  const [onPresentUnstake] = useModal(
    <StakeModal
      stakingTokenBalance={stakedBalance}
      isNativePool={isNativePool}
      pool={pool}
      stakingTokenPrice={stakingTokenPrice}
      isRemovingStake
    />,
  )

  const onStake = () => {
    onPresentStake()
  }

  const onUnstake = () => {
    onPresentUnstake()
  }

  const { targetRef, tooltip, tooltipVisible } = useTooltip(
    t("You've already staked the maximum amount you can stake in this pool!"),
    { placement: 'bottom' },
  )

  const reachStakingLimit = false
  const unlockDate = dayjs(userData.unlockTime * 1000).format('YYYY-MM-DD')
  // console.log(`unlocktime =`, unlockDate)

  const isUnlockTime = userData.unlockTime < Math.floor(Date.now() / 1000)

  // const isEnableToUnstake = isMembership ? isUnlockTime && !needBuyNFT : isUnlockTime
  const isEnableToUnstake = isUnlockTime

  if (!account) {
    return (
      <ActionContainer>
        <ActionTitles>
          <Text fontSize="12px" bold color="textSubtle" as="span" textTransform="uppercase">
            {t('Start staking')}
          </Text>
        </ActionTitles>
        <ActionContent>
          <ConnectWalletButton width="100%" />
        </ActionContent>
      </ActionContainer>
    )
  }

  if (!userDataLoaded) {
    return (
      <ActionContainer>
        <ActionTitles>
          <Text fontSize="12px" bold color="textSubtle" as="span" textTransform="uppercase">
            {t('Start staking')}
          </Text>
        </ActionTitles>
        <ActionContent>
          <Skeleton width={180} height="32px" marginTop={14} />
        </ActionContent>
      </ActionContainer>
    )
  }

  if (isMembership) {
    if (needBuyNFT) {
      return (
        <ActionContainer>
          <ActionTitles>
            <Text fontSize="12px" bold color="textSubtle" as="span" textTransform="uppercase">
              {t('Enable pool')}
            </Text>
          </ActionTitles>
          <ActionContent>
            <Button width="100%" onClick={handleBuyNFT} variant="secondary">
              {t('Mint NFT to Enable')}
            </Button>
          </ActionContent>
        </ActionContainer>
      )
    }
  }

  if (needsApproval) {
    return (
      <ActionContainer>
        <ActionTitles>
          <Text fontSize="12px" bold color="textSubtle" as="span" textTransform="uppercase">
            {t('Enable pool')}
          </Text>
        </ActionTitles>
        <ActionContent>
          <Button width="100%" disabled={requestedApproval} onClick={handleApprove} variant="secondary">
            {t('Enable')}
          </Button>
        </ActionContent>
      </ActionContainer>
    )
  }

  // Wallet connected, user data loaded and approved
  if (isNotVaultAndHasStake) {
    return (
      <ActionContainer isAutoVault={false}>
        <ActionTitles>
          <Text fontSize="12px" bold color="secondary" as="span" textTransform="uppercase">
            {isNativePool ? getNativeName(chainId) : stakingToken.symbol}{' '}
          </Text>
          <Text fontSize="12px" bold color="textSubtle" as="span" textTransform="uppercase">
            {t('Staked')}
          </Text>
          <Text fontSize="12px" bold color="textSubtle" as="span" textTransform="uppercase">
            {' Unlocked At '}
          </Text>
          <Text fontSize="12px" bold color="secondary" as="span" textTransform="uppercase">
            {unlockDate}
          </Text>
        </ActionTitles>
        <ActionContent>
          <Flex flex="1" pt="16px" flexDirection="column" alignSelf="flex-start">
            <Balance lineHeight="1" bold fontSize="20px" decimals={5} value={stakedTokenBalance} />
            <Balance
              fontSize="12px"
              display="inline"
              color="textSubtle"
              decimals={2}
              value={stakedTokenDollarBalance}
              unit=" USD"
              prefix="~"
            />
          </Flex>
          <IconButtonWrapper>
            <IconButton variant="secondary" onClick={onUnstake} disabled={!isEnableToUnstake} mr="6px">
              <MinusIcon color="primary" width="14px" />
            </IconButton>
            <IconButton
              variant="secondary"
              onClick={stakingTokenBalance.gt(0) ? onStake : onPresentTokenRequired}
              disabled={isFinished || isDisabled}
            >
              <AddIcon color="primary" width="14px" />
            </IconButton>
          </IconButtonWrapper>
          {tooltipVisible && tooltip}
        </ActionContent>
      </ActionContainer>
    )
  }

  return (
    <ActionContainer>
      <ActionTitles>
        <Text fontSize="12px" bold color="secondary" as="span" textTransform="uppercase">
          {t('Stake')}{' '}
        </Text>
        <Text fontSize="12px" bold color="textSubtle" as="span" textTransform="uppercase">
          {isNativePool ? getNativeName(chainId) : stakingToken.symbol}
        </Text>
      </ActionTitles>
      <ActionContent>
        <Button
          width="100%"
          onClick={stakingTokenBalance.gt(0) ? onStake : onPresentTokenRequired}
          variant="secondary"
          disabled={isFinished || isDisabled}
        >
          {t('Stake')}
        </Button>
      </ActionContent>
    </ActionContainer>
  )
}

export default Staked
