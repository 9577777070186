import React from 'react'
import styled, { keyframes } from 'styled-components'

const StyledSVG = styled.svg<{ size: string }>`
  height: ${({ size }) => size};
  width: ${({ size }) => size};
  path {
    stroke: ${({ stroke, theme }) => stroke ?? theme.colors.primary};
  }
`

export default function FuelIcon({
  size = '16px',
  stroke,
  ...rest
}: {
  size?: string
  stroke?: string
  [k: string]: any
}) {
  return (
    <StyledSVG viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" size={size} stroke={stroke} {...rest}>
      <path
        d="M16.1053 4.11499L15.4387 4.78165L17.6768 7.01974V9.21023C17.6768 9.49594 17.8673 9.68642 18.153 9.68642H19.1053C19.3911 9.68642 19.5816 9.49594 19.5816 9.21023V7.97213C19.5816 7.73403 19.4864 7.49594 19.2959 7.30546L16.1053 4.11499Z"
        fill="#C89211"
      />
      <path
        d="M16.2489 20.6387H4.82031V4.44821C4.82031 3.4006 5.67746 2.54346 6.72508 2.54346H14.3441C15.3918 2.54346 16.2489 3.4006 16.2489 4.44821V20.6387Z"
        fill="#00783F"
      />
      <path
        d="M20.1057 13.0187C19.6296 12.1615 19.1057 11.3044 19.1057 9.6853H18.1534C18.1534 11.5901 18.7248 12.5901 19.2962 13.4948C19.7248 14.2091 20.0582 14.8758 20.0582 15.8758C20.0582 16.3996 19.6296 16.8281 19.1057 16.8281C18.5819 16.8281 18.1534 16.3996 18.1534 15.8758V13.4948C18.1534 12.971 17.7248 12.5424 17.201 12.5424H16.2486V13.4948H17.201V15.8758C17.201 16.9233 18.0582 17.7805 19.1057 17.7805C20.1534 17.7805 21.0105 16.9233 21.0105 15.8758C21.0105 14.6376 20.5343 13.8281 20.1057 13.0187ZM2.91528 18.7329H21.0105V20.6376H2.91528V18.7329Z"
        fill="#344054"
      />
      <path
        d="M14.344 3.97217H6.72497C6.43926 3.97217 6.24878 4.16264 6.24878 4.44835V9.21026C6.24878 9.49597 6.43926 9.68645 6.72497 9.68645H14.344C14.6297 9.68645 14.8202 9.49597 14.8202 9.21026V4.44835C14.8202 4.16264 14.6297 3.97217 14.344 3.97217Z"
        fill="#B2EBF2"
      />
      <path
        d="M14.344 3.97217H6.72497C6.43926 3.97217 6.24878 4.16264 6.24878 4.44835V9.21026C6.24878 9.49597 6.43926 9.68645 6.72497 9.68645H14.344C14.6297 9.68645 14.8202 9.49597 14.8202 9.21026V4.44835C14.8202 4.16264 14.6297 3.97217 14.344 3.97217Z"
        fill="#F9F5FF"
      />
      <path d="M11.0093 9.68645L13.2474 6.97217L9.91406 9.68645H11.0093Z" fill="#F44336" />
      <path
        d="M7.20011 6.73322L6.53345 6.06656C6.6763 5.9237 6.77154 5.82846 6.9144 5.73322L7.53345 6.49513C7.39059 6.54274 7.29534 6.63799 7.20011 6.73322ZM13.8668 6.73322C13.7715 6.63799 13.6763 6.54274 13.5334 6.44751L14.1049 5.6856C14.2477 5.78085 14.3906 5.9237 14.4858 6.01894L13.8668 6.73322ZM8.62868 5.78085L8.24773 4.9237C8.39059 4.87608 8.58106 4.78085 8.72392 4.73322L9.00963 5.63799C8.86677 5.6856 8.77154 5.73322 8.62868 5.78085ZM12.3906 5.78085C12.2477 5.73322 12.1049 5.6856 11.962 5.63799L12.2477 4.73322C12.3906 4.78085 12.5811 4.82846 12.724 4.9237L12.3906 5.78085ZM10.2953 5.39989L10.2477 4.44751C10.4382 4.44751 10.5811 4.44751 10.7715 4.44751L10.7239 5.39989C10.5811 5.39989 10.4382 5.39989 10.2953 5.39989ZM11.4858 9.6856H9.58106C9.58106 9.1618 10.0096 8.73322 10.5334 8.73322C11.0573 8.73322 11.4858 9.1618 11.4858 9.6856Z"
        fill="#455A64"
      />
    </StyledSVG>
  )
}
