import { CurrencyAmount, Token } from '@arborswap/sdk'
import { createReducer } from '@reduxjs/toolkit'
import { Field, replacePortalState, selectCurrency, setRecipient, typeInput, setGas } from './actions'

export interface PortalState {
  readonly independentField: Field
  readonly typedValue: string
  readonly [Field.INPUT]: {
    readonly currencyId: string | undefined
  }
  readonly [Field.OUTPUT]: {
    readonly currencyId: string | undefined
  }
  // the typed recipient address or ENS name, or null if swap should go to sender
  readonly recipient: string | null
  readonly withGas: boolean
}

export interface PortalTx {
  tokenIn: Token
  addressTo: string
  ammount: CurrencyAmount
  withGas: boolean
}

const initialState: PortalState = {
  independentField: Field.INPUT,
  typedValue: '',
  [Field.INPUT]: {
    currencyId: '',
  },
  [Field.OUTPUT]: {
    currencyId: '',
  },
  recipient: null,
  withGas: false,
}

export default createReducer<PortalState>(initialState, (builder) =>
  builder
    .addCase(replacePortalState, (state, { payload: { typedValue, recipient, field, inputCurrencyId, withGas } }) => {
      return {
        [Field.INPUT]: {
          currencyId: inputCurrencyId,
        },
        [Field.OUTPUT]: {
          currencyId: inputCurrencyId,
        },
        independentField: field,
        typedValue,
        recipient,
        withGas,
      }
    })
    .addCase(selectCurrency, (state, { payload: { currencyId, field } }) => {
      const otherField = field === Field.INPUT ? Field.OUTPUT : Field.INPUT
      if (currencyId === state[otherField].currencyId) {
        // the case where we have to swap the order
        return {
          ...state,
          independentField: state.independentField === Field.INPUT ? Field.OUTPUT : Field.INPUT,
          [field]: { currencyId },
          [otherField]: { currencyId: state[field].currencyId },
        }
      }
      // the normal case
      return {
        ...state,
        [field]: { currencyId },
      }
    })
    .addCase(typeInput, (state, { payload: { field, typedValue } }) => {
      return {
        ...state,
        independentField: field,
        typedValue,
      }
    })
    .addCase(setRecipient, (state, { payload: { recipient } }) => {
      state.recipient = recipient
    })
    .addCase(setGas, (state, { payload: { withGas } }) => {
      state.withGas = withGas
    }),
)
