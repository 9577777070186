import { CurrencyAmount, Token, TokenAmount } from '@arborswap/sdk'
import { useMemo } from 'react'
import { formatInputToStringValue } from 'utils/bigNumber'

import { usePortalContract } from './useContract'
import { useSingleCallResult } from '../state/multicall/hooks'

const tryParseResult = (result: unknown): string | undefined => {
  try {
    const res = result?.toString()
    // console.log('res', res)

    if (res === '0,0') {
      return undefined
    }
    return res
  } catch (error) {
    return undefined
  }
}

export interface PortalRelayInfo {
  feeAmount: TokenAmount
  receiveAmount: TokenAmount
}

function useRelayPortal(token?: Token, amount?: string, withGas?: string): PortalRelayInfo | undefined {
  const contract = usePortalContract()

  const inputs = useMemo(
    () => [token?.address, formatInputToStringValue(amount, token?.decimals), withGas],
    [token, amount, withGas],
  )
  const results = useSingleCallResult(contract, 'calculateRelayFee', inputs).result

  return useMemo(
    () =>
      token && inputs && results
        ? {
            feeAmount: tryParseResult(results[0][0])
              ? new TokenAmount(token, results[0][0].toString())
              : new TokenAmount(token, '0'),
            receiveAmount: tryParseResult(results[0][1])
              ? new TokenAmount(token, results[0][1].toString())
              : new TokenAmount(token, '0'),
          }
        : undefined,
    [token, results, inputs],
  )
}

export default useRelayPortal
