import tokens from '../tokens'
import { PoolCategory, PoolConfig } from '../types'

export const rbaPools: PoolConfig[] = [
  {
    isNative: true,
    isNativeEarning: true,
    chainId: 158,
    sousId: 158001,
    stakingToken: tokens.wrba,
    earningToken: tokens.wrba,
    contractAddress: {
      158: '0xbF3743E50319Ba92DF1378Fc65b7F0B2a94D4c4D',
    },
    depositAddress: {
      158: '0x571Bf3340D654eBc7342B9600186cc873393bb36',
    },
    customRewardAddress: {
      158: '0x061F197ced53E0bFa185c6C3eb7195b7173c6afb',
    },
    poolCategory: PoolCategory.CORE,
    isLock: true,
    lockTime: 730,
    harvest: true,
    // lockInfo: 'Unlocks on mainnet',
    rate: 5,
    tokenPerBlock: '10',
    sortOrder: 1,
    isFinished: true,
    isDisabled: false,
    isMembership: false,
    isCustomReward: false,
  },
]

export const rbaTestnetPools: PoolConfig[] = [
  {
    isNative: true,
    isNativeEarning: true,
    chainId: 159,
    sousId: 159101,
    stakingToken: tokens.wrba,
    earningToken: tokens.wrba,
    contractAddress: {
      159: '0x0Fe2c7494cB7266C53Cc16136ED66dF3CF8d322C',
    },
    depositAddress: {
      159: '0x80d7ce53fb2b36073bdB760823669CE8d0272876',
    },
    customRewardAddress: {
      159: '0xD5d6D4D73B2fC5A2E20A6C4b426d91B9e384d170',
    },
    poolCategory: PoolCategory.NATIVECOIN,
    isLock: true,
    lockTime: 730,
    harvest: true,
    // lockInfo: 'Unlocks on mainnet',
    rate: 5,
    tokenPerBlock: '10',
    sortOrder: 1,
    isFinished: false,
    isDisabled: false,
    isMembership: false,
    isCustomReward: false,
  },
  {
    isNative: true,
    isNativeEarning: true,
    chainId: 159,
    sousId: 159102,
    stakingToken: tokens.wrba,
    earningToken: tokens.wrba,
    contractAddress: {
      159: '0xc74C3994624bbeb6cBb56845Da8060391d2880E1',
    },
    depositAddress: {
      159: '0x7905f89e0782c496BF3f283Dc5cb0aD30E2B5e00',
    },
    customRewardAddress: {
      159: '0xD5d6D4D73B2fC5A2E20A6C4b426d91B9e384d170',
    },
    poolCategory: PoolCategory.NATIVECOIN,
    isLock: false,
    lockTime: 730,
    harvest: true,
    // lockInfo: 'Unlocks on mainnet',
    rate: 5,
    tokenPerBlock: '10',
    sortOrder: 1,
    isFinished: false,
    isDisabled: false,
    isMembership: false,
    isCustomReward: false,
  },
  {
    isNative: false,
    chainId: 159,
    sousId: 159001,
    stakingToken: tokens.wrba,
    earningToken: tokens.wrba,
    contractAddress: {
      159: '0x34FB08a71656B8e3DaC4736a4edb7c27b9C11796',
    },
    depositAddress: {
      159: '0x34FB08a71656B8e3DaC4736a4edb7c27b9C11796',
    },
    customRewardAddress: {
      159: '0x34FB08a71656B8e3DaC4736a4edb7c27b9C11796',
    },
    poolCategory: PoolCategory.CORE,
    isLock: false,
    lockTime: 365,
    harvest: true,
    // lockInfo: 'Unlocks on mainnet',
    rate: 5,
    tokenPerBlock: '10',
    sortOrder: 1,
    isFinished: false,
    isDisabled: false,
    isMembership: false,
    isCustomReward: false,
  },
]
