import React, { useCallback, useMemo } from 'react'
import { currencyEquals, Trade } from '@arborswap/sdk'
import { InjectedModalProps } from '@arborswap/uikit'
import { useTranslation } from 'contexts/Localization'
import { PortalTx } from 'state/portal/reducer'
import { PortalRelayInfo } from 'hooks/useRelayPortal'
import TransactionConfirmationModal, {
  ConfirmationModalContent,
  TransactionErrorContent,
} from 'components/TransactionConfirmationModal'
import SwapModalFooter from './SwapModalFooter'
import SwapModalHeader from './SwapModalHeader'

/**
 * Returns true if the trade requires a confirmation of details before we can submit it
 * @param tradeA trade A
 * @param tradeB trade B
 */
function tradeMeaningfullyDiffers(tradeA: Trade, tradeB: Trade): boolean {
  return (
    tradeA.tradeType !== tradeB.tradeType ||
    !currencyEquals(tradeA.inputAmount.currency, tradeB.inputAmount.currency) ||
    !tradeA.inputAmount.equalTo(tradeB.inputAmount) ||
    !currencyEquals(tradeA.outputAmount.currency, tradeB.outputAmount.currency) ||
    !tradeA.outputAmount.equalTo(tradeB.outputAmount)
  )
}
interface ConfirmSwapModalProps {
  portalTx?: PortalTx
  infoTx?: PortalRelayInfo
  attemptingTxn: boolean
  txHash?: string
  recipient: string | null
  onConfirm: () => void
  customOnDismiss?: () => void
}

const ConfirmSwapModal: React.FC<InjectedModalProps & ConfirmSwapModalProps> = ({
  portalTx,
  infoTx,
  onConfirm,
  onDismiss,
  customOnDismiss,
  recipient,
  attemptingTxn,
  txHash,
}) => {
  const { t } = useTranslation()

  const modalHeader = useCallback(() => {
    return portalTx ? <SwapModalHeader portalTx={portalTx} portalInfo={infoTx} recipient={recipient} /> : null
  }, [portalTx, infoTx, recipient])

  const modalBottom = useCallback(() => {
    return portalTx ? <SwapModalFooter onConfirm={onConfirm} /> : null
  }, [portalTx, onConfirm])

  // text to show while loading
  const pendingText = t('Bridging %amountA% %symbolA% for %amountB% %symbolB%', {
    amountA: portalTx?.ammount?.toSignificant(6) ?? '',
    symbolA: portalTx?.tokenIn?.symbol ?? '',
    amountB: infoTx?.receiveAmount?.toSignificant(6) ?? '',
    symbolB: portalTx?.tokenIn?.symbol ?? '',
  })

  const confirmationContent = useCallback(
    () => <ConfirmationModalContent topContent={modalHeader} bottomContent={modalBottom} />,

    [modalBottom, modalHeader],
  )

  return (
    <TransactionConfirmationModal
      title={t('Confirm Bridge')}
      onDismiss={onDismiss}
      customOnDismiss={customOnDismiss}
      attemptingTxn={attemptingTxn}
      hash={txHash}
      content={confirmationContent}
      pendingText={pendingText}
      currencyToAdd={portalTx?.tokenIn}
    />
  )
}

export default ConfirmSwapModal
