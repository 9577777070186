import React, { useMemo } from 'react'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import tokens from 'config/constants/tokens'
import { Trade, TradeType } from '@arborswap/sdk'
import { Button, Text, ErrorIcon, ArrowDownIcon } from '@arborswap/uikit'
import { PortalTx } from 'state/portal/reducer'
import { isAddress, shortenAddress } from 'utils'
import { PortalRelayInfo } from 'hooks/useRelayPortal'
import { AutoColumn } from 'components/Layout/Column'
import { CurrencyLogo } from 'components/Logo'
import { RowBetween, RowFixed } from 'components/Layout/Row'
import { TruncatedText } from './styleds'

export default function SwapModalHeader({
  portalTx,
  portalInfo,
  recipient,
}: {
  portalTx: PortalTx
  portalInfo: PortalRelayInfo
  recipient: string | null
}) {
  const { chainId } = useActiveWeb3React()
  return (
    <AutoColumn gap="md">
      <RowBetween align="flex-end">
        <RowFixed gap="0px">
          <CurrencyLogo currency={portalTx.tokenIn} size="24px" style={{ marginRight: '12px' }} />
          <TruncatedText fontSize="24px" color="primary">
            {portalTx.ammount.toSignificant(6)}
          </TruncatedText>
        </RowFixed>
        <RowFixed gap="0px">
          <Text fontSize="24px" ml="10px">
            {portalTx.tokenIn.symbol}
          </Text>
        </RowFixed>
      </RowBetween>
      <RowFixed>
        <ArrowDownIcon width="16px" ml="4px" />
      </RowFixed>
      <RowBetween align="flex-end">
        <RowFixed gap="0px">
          <CurrencyLogo currency={portalTx.tokenIn} size="24px" style={{ marginRight: '12px' }} />
          <TruncatedText fontSize="24px" color="primary">
            {portalInfo?.receiveAmount.toSignificant(6)}
          </TruncatedText>
        </RowFixed>
        <RowFixed gap="0px">
          <Text fontSize="24px" ml="10px">
            {portalTx.tokenIn.symbol}
          </Text>
        </RowFixed>
      </RowBetween>
      {portalTx.withGas && (
        <RowBetween align="flex-end">
          <RowFixed gap="0px">
            <CurrencyLogo currency={tokens.rba.address[chainId]} size="24px" style={{ marginRight: '12px' }} />
            <TruncatedText fontSize="24px" color="primary">
              1
            </TruncatedText>
          </RowFixed>
          <RowFixed gap="0px">
            <Text fontSize="24px" ml="10px">
              RBA
            </Text>
          </RowFixed>
        </RowBetween>
      )}
      {recipient !== null ? (
        <AutoColumn justify="flex-start" gap="sm" style={{ padding: '12px 0 0 0px' }}>
          <Text color="textSubtle">
            Output will be sent to{' '}
            <b title={recipient}>{isAddress(recipient) ? shortenAddress(recipient) : recipient}</b>
          </Text>
        </AutoColumn>
      ) : null}
    </AutoColumn>
  )
}
