import { useMemo } from 'react'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import { useWeb3React } from '@web3-react/core'
import { getMulticallAddress } from 'utils/addressHelpers'

import { poolsConfig } from 'config/constants'
// Imports below migrated from Exchange useContract.ts
import { Contract } from '@ethersproject/contracts'
import { PoolCategory, PoolConfig } from 'config/constants/types'
import { ChainId, WETH } from '@arborswap/sdk'
import { abi as IUniswapV2PairABI } from '@uniswap/v2-core/build/IUniswapV2Pair.json'
import { Erc20 } from 'config/abi/types'
import ENS_PUBLIC_RESOLVER_ABI from '../config/abi/ens-public-resolver.json'
import ENS_ABI from '../config/abi/ens-registrar.json'
import { ERC20_BYTES32_ABI } from '../config/abi/erc20'
import ERC20_ABI from '../config/abi/erc20.json'
import WETH_ABI from '../config/abi/weth.json'
import multiCallAbi from '../config/abi/Multicall.json'
import normalStake from '../config/abi/normalStake.json'
import rewardWallet from '../config/abi/newReward.json'
import lockStake from '../config/abi/lockStake.json'
import nativelockStake from '../config/abi/nativeLock.json'
import nativeopenStake from '../config/abi/nativeOpen.json'
import foreignPortalAbi from '../config/abi/foreignPortal.json'
import depositWallet from '../config/abi/stakeTreasury.json'
import { getContract } from '../utils'
import {
  useDepositAddress,
  useMulticallAddress,
  usePortalAddress,
  useRewardAddress,
  useSousChefAddress,
} from './useAddress'
/**
 * Helper hooks to get specific contracts (by ABI)
 */

function useContract(address: string | undefined, ABI: any, withSignerIfPossible = true): Contract | null {
  const { library, account } = useActiveWeb3React()

  return useMemo(() => {
    if (!address || address === '0x0000000000000000000000000000000000000000' || !ABI || !library) return null
    try {
      return getContract(address, ABI, library, withSignerIfPossible && account ? account : undefined)
    } catch (error) {
      console.error('Failed to get contract', error)
      return null
    }
  }, [address, ABI, library, withSignerIfPossible, account])
}

export const useERC20 = (address: string) => {
  return useContract(address, ERC20_ABI) as Erc20
}

// Code below migrated from Exchange useContract.ts

// returns null on errors

export function useTokenContract(tokenAddress?: string, withSignerIfPossible?: boolean): Contract | null {
  return useContract(tokenAddress, ERC20_ABI, withSignerIfPossible)
}

export function useWETHContract(withSignerIfPossible?: boolean): Contract | null {
  const { chainId } = useActiveWeb3React()
  return useContract(chainId ? WETH[chainId].address : undefined, WETH_ABI, withSignerIfPossible)
}

export function useENSRegistrarContract(withSignerIfPossible?: boolean): Contract | null {
  const { chainId } = useActiveWeb3React()
  let address: string | undefined
  if (chainId) {
    // eslint-disable-next-line default-case
    switch (chainId) {
      case ChainId.BSC:
      case ChainId.BSC_TESTNET:
        address = '0x00000000000C2E074eC69A0dFb2997BA6C7d2e1e'
        break
    }
  }
  return useContract(address, ENS_ABI, withSignerIfPossible)
}

export function useENSResolverContract(address: string | undefined, withSignerIfPossible?: boolean): Contract | null {
  return useContract(address, ENS_PUBLIC_RESOLVER_ABI, withSignerIfPossible)
}

export function useBytes32TokenContract(tokenAddress?: string, withSignerIfPossible?: boolean): Contract | null {
  return useContract(tokenAddress, ERC20_BYTES32_ABI, withSignerIfPossible)
}

export function usePairContract(pairAddress?: string, withSignerIfPossible?: boolean): Contract | null {
  return useContract(pairAddress, IUniswapV2PairABI, withSignerIfPossible)
}

export function useMulticallContract(): Contract | null {
  return useContract(useMulticallAddress(), multiCallAbi, false)
}

export function usePortalContract(): Contract | null {
  return useContract(usePortalAddress(), foreignPortalAbi, false)
}

export const useDepositWallet = (id) => {
  return useContract(useDepositAddress(id), depositWallet, true)
}

export const useSousChef = (id) => {
  const config = poolsConfig.find((pool) => pool.sousId === id)
  let abi = normalStake
  if (config.poolCategory === PoolCategory.NATIVECOIN) {
    abi = config.isLock ? nativelockStake : nativeopenStake
  } else {
    abi = config.isLock ? lockStake : normalStake
  }
  return useContract(useSousChefAddress(id), abi, true)
}

export const useRewardWallet = (id) => {
  return useContract(useRewardAddress(id), rewardWallet, true)
}
