import React, { useCallback, useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'
import { CurrencyAmount, JSBI, Token, Trade } from '@arborswap/sdk'
// import BigNumber from 'bignumber.js'
import QuestionHelper from 'components/QuestionHelper'
import { Button, Text, ArrowDownIcon, Box, useModal, Toggle, Flex } from '@arborswap/uikit'
import { halfAmountSpend } from 'utils/halfAmountSpend'
import { PortalTx } from 'state/portal/reducer'
import { RouteComponentProps } from 'react-router-dom'
import { useTranslation } from 'contexts/Localization'
import { usePortalCallback } from 'hooks/usePortalCallback'

import PortalInfoPanel from 'components/PortalInputPanel/PortalInfoPanel'
import AddressInputPanel from './components/AddressInputPanel'
import Column, { AutoColumn } from '../../components/Layout/Column'
import ConfirmSwapModal from './components/ConfirmSwapModal'
import PortalInputPanel from '../../components/PortalInputPanel'
import { AutoRow, RowBetween } from '../../components/Layout/Row'
import { ArrowWrapper, Wrapper } from './components/styleds'
import ProgressSteps from './components/ProgressSteps'
import { AppPortalBody } from '../../components/App'
import ConnectWalletButton from '../../components/ConnectWalletButton'

import useActiveWeb3React from '../../hooks/useActiveWeb3React'
import { ApprovalState, useApprovaCallbackPortal, useApproveCallbackFromTrade } from '../../hooks/useApproveCallback'
import { Field } from '../../state/portal/actions'
import { usePortalActionHandlers, useDerivedPortalInfo, usePortalState } from '../../state/portal/hooks'
import { maxAmountSpend } from '../../utils/maxAmountSpend'
import CircleLoader from '../../components/Loader/CircleLoader'
import { PortalPage } from '../Page'
import PortalInfoBox from './components/PortalInfoBox'
import FuelIcon from './components/FuelIcon'

const Label = styled(Text)`
  font-size: 12px;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.secondary};
`

const RefuelDiv = styled.div`
  padding: 1rem;
  background-color: ${({ theme }) => theme.colors.backgroundAlt};
  border-radius: 16px;
  margin-top: 1rem;
`

export default function Portal({ history }: RouteComponentProps) {
  const { t } = useTranslation()

  // const

  const { account, chainId } = useActiveWeb3React()

  // swap state
  const { independentField, typedValue, recipient, withGas } = usePortalState()
  const {
    currencyBalances,
    parsedAmount,
    currencies,
    inputError: swapInputError,
    portalInfo,
    portalTx,
  } = useDerivedPortalInfo()

  const parsedAmounts = {
    [Field.INPUT]: parsedAmount,
    [Field.OUTPUT]: parsedAmount,
  }

  const { onCurrencySelection, onUserInput, onChangeRecipient, onGasToggle } = usePortalActionHandlers()
  const isValid = !swapInputError
  const dependentField: Field = independentField === Field.INPUT ? Field.OUTPUT : Field.INPUT

  const handleTypeInput = useCallback(
    (value: string) => {
      onUserInput(Field.INPUT, value)
    },
    [onUserInput],
  )

  const handleGasTogle = useCallback(
    () => {
      onGasToggle(!portalTx.withGas)
    },
    // [onGasToggle],
    [onGasToggle, portalTx.withGas],
  )
  const handleTypeOutput = useCallback(
    (value: string) => {
      onUserInput(Field.OUTPUT, value)
    },
    [onUserInput],
  )

  // modal and loading
  const [{ txToConfirm, swapErrorMessage, attemptingTxn, txHash, customRecipient }, setPortalState] = useState<{
    txToConfirm: PortalTx | undefined
    attemptingTxn: boolean
    swapErrorMessage: string | undefined
    txHash: string | undefined
    customRecipient: boolean
  }>({
    txToConfirm: undefined,
    attemptingTxn: false,
    swapErrorMessage: undefined,
    txHash: undefined,
    customRecipient: false,
  })

  const formattedAmounts = {
    [independentField]: typedValue,
    [dependentField]: parsedAmounts[independentField]?.toExact() ?? '',
  }

  // check whether the user has approved the router on the input token
  const [approval, approveCallback] = useApprovaCallbackPortal(parsedAmounts[Field.INPUT], chainId)

  // check if user has gone through approval process, used to show two step buttons, reset on token change
  const [approvalSubmitted, setApprovalSubmitted] = useState<boolean>(false)

  // mark when a user has submitted an approval, reset onTokenSelection for input field
  useEffect(() => {
    if (approval === ApprovalState.PENDING) {
      setApprovalSubmitted(true)
    }
  }, [approval, approvalSubmitted])

  const maxAmountInput: CurrencyAmount | undefined = maxAmountSpend(currencyBalances[Field.INPUT])
  const halfAmountInput: CurrencyAmount | undefined = halfAmountSpend(currencyBalances[Field.INPUT])
  const atMaxAmountInput = Boolean(maxAmountInput && parsedAmounts[Field.INPUT]?.equalTo(maxAmountInput))

  // show approve flow when: no error on inputs, not approved or pending, or approved in current session
  // never show if price impact is above threshold in non expert mode
  const showApproveFlow =
    approval === ApprovalState.NOT_APPROVED ||
    approval === ApprovalState.PENDING ||
    (approvalSubmitted && approval === ApprovalState.APPROVED)

  const handleConfirmDismiss = useCallback(() => {
    setPortalState({ txToConfirm, attemptingTxn, swapErrorMessage, txHash, customRecipient })
    // if there was a tx hash, we want to clear the input
    if (txHash) {
      onUserInput(Field.INPUT, '')
    }
  }, [attemptingTxn, onUserInput, swapErrorMessage, txToConfirm, txHash, customRecipient])

  const handleInputSelect = useCallback(
    (inputCurrency) => {
      setApprovalSubmitted(false) // reset 2 step UI for approvals
      onCurrencySelection(Field.INPUT, inputCurrency)
    },
    [onCurrencySelection],
  )

  const handleMaxInput = useCallback(() => {
    if (maxAmountInput) {
      onUserInput(Field.INPUT, maxAmountInput.toExact())
    }
  }, [maxAmountInput, onUserInput])

  const handleHalfInput = useCallback(() => {
    if (halfAmountInput) {
      onUserInput(Field.INPUT, halfAmountInput.toExact())
    }
  }, [halfAmountInput, onUserInput])

  const { callback: portalCallback, error: portalCallbackError } = usePortalCallback(txToConfirm, portalInfo)

  const handleSwap = useCallback(() => {
    if (!portalCallback) {
      return
    }
    setPortalState({
      attemptingTxn: true,
      txToConfirm,
      swapErrorMessage: undefined,
      txHash: undefined,
      customRecipient,
    })
    portalCallback()
      .then((hash) => {
        setPortalState({
          attemptingTxn: false,
          txToConfirm,
          swapErrorMessage: undefined,
          txHash: hash,
          customRecipient,
        })
      })
      .catch((error) => {
        setPortalState({
          attemptingTxn: false,
          txToConfirm,
          swapErrorMessage: error.message,
          txHash: undefined,
          customRecipient,
        })
      })
  }, [portalCallback, txToConfirm, customRecipient])

  const [onPresentConfirmModal] = useModal(
    <ConfirmSwapModal
      portalTx={txToConfirm}
      infoTx={portalInfo}
      attemptingTxn={attemptingTxn}
      txHash={txHash}
      recipient={recipient}
      onConfirm={handleSwap}
      customOnDismiss={handleConfirmDismiss}
    />,
    true,
    true,
    'confirmSwapModal',
  )

  return (
    <PortalPage>
      <AppPortalBody>
        <Wrapper id="swap-page">
          <AutoColumn gap="md">
            <PortalInputPanel
              label="From"
              value={formattedAmounts[Field.INPUT]}
              showMaxButton={!atMaxAmountInput}
              currency={currencies[Field.INPUT]}
              onUserInput={handleTypeInput}
              onMax={handleMaxInput}
              onHalf={handleHalfInput}
              onCurrencySelect={handleInputSelect}
              otherCurrency={currencies[Field.OUTPUT]}
              id="swap-currency-input"
            />
            <AutoColumn justify="space-between">
              <AutoRow justify="center" style={{ padding: '0 1rem' }}>
                <ArrowWrapper>
                  <ArrowDownIcon width="16px" color="primary" />
                </ArrowWrapper>
              </AutoRow>
            </AutoColumn>
            <PortalInfoPanel
              label="To"
              value={portalInfo?.receiveAmount.toSignificant(6) ?? ''}
              currency={portalTx?.tokenIn}
              id="portal-currency-output"
            />

            <RefuelDiv>
              <AutoColumn justify="space-between">
                <RowBetween>
                  <Flex alignItems="center">
                    <FuelIcon size="32px" />
                    <Flex marginLeft="5px" flexDirection="column" alignItems="left">
                      <Flex alignItems="center">
                        <Text fontSize="16px">Enable Refuel</Text>
                        <QuestionHelper
                          text="Enabling refuel will get 1 RBA for transactions on Roburna Network."
                          placement="top-start"
                          ml="4px"
                        />
                      </Flex>
                      <Text fontSize="0.75rem">Get RBA for transactions on Roburna</Text>
                    </Flex>
                  </Flex>
                  <Toggle checked={portalTx.withGas} onChange={handleGasTogle} scale="md" />
                </RowBetween>
              </AutoColumn>
            </RefuelDiv>
          </AutoColumn>
          {/* <Box mt="1rem">
            <AutoColumn justify="space-between">
              {portalTx && portalInfo && PortalInfoBox({ portalTx, portalInfo })}
            </AutoColumn>
          </Box> */}
          <Box mt="1rem">
            {!account ? (
              <ConnectWalletButton width="100%" />
            ) : showApproveFlow ? (
              <RowBetween>
                <Button
                  variant={approval === ApprovalState.APPROVED ? 'success' : 'primary'}
                  onClick={approveCallback}
                  disabled={approval !== ApprovalState.NOT_APPROVED || approvalSubmitted}
                  width="48%"
                >
                  {approval === ApprovalState.PENDING ? (
                    <AutoRow gap="6px" justify="center">
                      {t('Enabling')} <CircleLoader stroke="white" />
                    </AutoRow>
                  ) : approvalSubmitted && approval === ApprovalState.APPROVED ? (
                    t('Enabled')
                  ) : (
                    t('Enable %asset%', { asset: currencies[Field.INPUT]?.symbol ?? '' })
                  )}
                </Button>
                <Button
                  variant="primary"
                  onClick={() => {
                    setPortalState({
                      txToConfirm: portalTx,
                      attemptingTxn: false,
                      swapErrorMessage: undefined,
                      txHash: undefined,
                      customRecipient,
                    })
                    onPresentConfirmModal()
                  }}
                  width="48%"
                  id="swap-button"
                  disabled={approval !== ApprovalState.APPROVED || !isValid}
                >
                  {t('Bridge')}
                </Button>
              </RowBetween>
            ) : (
              <Button
                variant="primary"
                onClick={() => {
                  setPortalState({
                    txToConfirm: portalTx,
                    attemptingTxn: false,
                    swapErrorMessage: undefined,
                    txHash: undefined,
                    customRecipient,
                  })
                  onPresentConfirmModal()
                }}
                id="swap-button"
                width="100%"
                disabled={approval !== ApprovalState.APPROVED || !isValid}
              >
                {t('Bridge')}
              </Button>
            )}
            {showApproveFlow && (
              <Column style={{ marginTop: '1rem' }}>
                <ProgressSteps steps={[approval === ApprovalState.APPROVED]} />
              </Column>
            )}
          </Box>
          <AutoColumn>
            <RefuelDiv>
              <AutoColumn justify="space-between">
                <RowBetween>
                  <Flex alignItems="center">
                    <Text fontSize="14px">Recipient Address </Text>
                    <QuestionHelper
                      text="Enable custom recipient to input a custom address. Otherwise, the transaction will be sent to your wallet."
                      placement="top-start"
                      ml="4px"
                    />
                  </Flex>
                  <Toggle
                    checked={customRecipient}
                    onChange={() => {
                      setPortalState({
                        txToConfirm: portalTx,
                        attemptingTxn: false,
                        swapErrorMessage: undefined,
                        txHash: undefined,
                        customRecipient: !customRecipient,
                      })
                    }}
                    scale="md"
                  />
                </RowBetween>
              </AutoColumn>
            </RefuelDiv>
            {customRecipient && (
              <AutoColumn justify="space-between">
                <AddressInputPanel id="swap-address-input" value={recipient} onChange={onChangeRecipient} />
              </AutoColumn>
            )}
          </AutoColumn>
        </Wrapper>
      </AppPortalBody>
    </PortalPage>
  )
}
