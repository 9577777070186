// Network chain ids

export const CHAIN_ID = {
  ETHEREUM: 1,
  BSC: 56,
  BSC_TESTNET: 97,
  ROBURNA: 158,
  ROBURNA_TESTNET: 159,
}

export const SUPPORTED_CHAINS = [
  CHAIN_ID.ETHEREUM,
  CHAIN_ID.BSC,
  CHAIN_ID.BSC_TESTNET,
  CHAIN_ID.ROBURNA,
  CHAIN_ID.ROBURNA_TESTNET,
]

export const DEFAULT_CHAIN_ID = CHAIN_ID.BSC

// Network labels

export const NETWORK_LABEL = {
  [CHAIN_ID.ETHEREUM]: 'Ethereum',
  [CHAIN_ID.BSC]: 'Binance Smart Chain',
  [CHAIN_ID.BSC_TESTNET]: 'BSC Testnet',
  [CHAIN_ID.ROBURNA]: 'Roburna',
  [CHAIN_ID.ROBURNA_TESTNET]: 'Roburna Testnet',
}

// Network icons

export const NETWORK_ICON = {
  [CHAIN_ID.ETHEREUM]: '/images/headericons/EthereumChain.svg',
  [CHAIN_ID.BSC]: '/images/headericons/BinanceSmartChainBadge.svg',
  [CHAIN_ID.BSC_TESTNET]: '/images/headericons/BinanceSmartChainBadge.svg',
  [CHAIN_ID.ROBURNA]: '/images/headericons/rba.png',
  [CHAIN_ID.ROBURNA_TESTNET]: '/images/headericons/rba.png',
}

export const NETWORK_INFO_LINK = {
  [CHAIN_ID.ETHEREUM]: 'https://info.roburna.com',
  [CHAIN_ID.BSC]: 'https://info.roburna.com',
  [CHAIN_ID.BSC_TESTNET]: 'https://info.roburna.com',
  [CHAIN_ID.ROBURNA]: 'https://info.roburna.com',
  [CHAIN_ID.ROBURNA_TESTNET]: 'https://info.roburna.com',
}

// Network RPC nodes
export const NETWORK_RPC = {
  [CHAIN_ID.ETHEREUM]: ['https://api.securerpc.com/v1', 'https://eth.llamarpc.com'],
  [CHAIN_ID.BSC]: [
    'https://bsc-dataseed1.ninicoin.io',
    'https://bsc-dataseed.binance.org/',
    'https://bsc-dataseed1.defibit.io',
  ],
  [CHAIN_ID.BSC_TESTNET]: ['https://data-seed-prebsc-2-s3.binance.org:8545/'],
  [CHAIN_ID.ROBURNA]: ['https://dataseed.roburna.com'],
  [CHAIN_ID.ROBURNA_TESTNET]: ['https://preseed-testnet-1.roburna.com'],
}

// Network block explorers

export const BLOCK_EXPLORER = {
  [CHAIN_ID.ETHEREUM]: 'https://etherscan.io',
  [CHAIN_ID.BSC]: 'https://bscscan.com',
  [CHAIN_ID.BSC_TESTNET]: 'https://testnet.bscscan.com/',
  [CHAIN_ID.ROBURNA]: 'https://rbascan.com',
  [CHAIN_ID.ROBURNA_TESTNET]: 'https://testnet.rbascan.com',
}

export const CHAIN_PARAMS = {
  [CHAIN_ID.ETHEREUM]: {
    chainId: '0x1',
    chainName: 'Ethereum',
    nativeCurrency: {
      name: 'ETH',
      symbol: 'ETH',
      decimals: 18,
    },
    rpcUrls: NETWORK_RPC[CHAIN_ID.ETHEREUM],
    blockExplorerUrls: [BLOCK_EXPLORER[CHAIN_ID.ETHEREUM]],
  },
  [CHAIN_ID.BSC]: {
    chainId: '0x38',
    chainName: 'Binance Smart Chain',
    nativeCurrency: {
      name: 'bnb',
      symbol: 'BNB',
      decimals: 18,
    },
    rpcUrls: NETWORK_RPC[CHAIN_ID.BSC],
    blockExplorerUrls: [BLOCK_EXPLORER[CHAIN_ID.BSC]],
  },
  [CHAIN_ID.BSC_TESTNET]: {
    chainId: '0x61',
    chainName: 'Binance Smart Chain Testnet',
    nativeCurrency: {
      name: 'bnb',
      symbol: 'tBNB',
      decimals: 18,
    },
    rpcUrls: NETWORK_RPC[CHAIN_ID.BSC_TESTNET],
    blockExplorerUrls: [BLOCK_EXPLORER[CHAIN_ID.BSC_TESTNET]],
  },
  [CHAIN_ID.ROBURNA]: {
    chainId: '0x9e',
    chainName: 'Roburna',
    nativeCurrency: {
      name: 'RBA',
      symbol: 'RBA',
      decimals: 18,
    },
    rpcUrls: NETWORK_RPC[CHAIN_ID.ROBURNA],
    blockExplorerUrls: [BLOCK_EXPLORER[CHAIN_ID.ROBURNA]],
  },
  [CHAIN_ID.ROBURNA_TESTNET]: {
    chainId: '0x9f',
    chainName: 'Roburna Testnet',
    nativeCurrency: {
      name: 'RBA',
      symbol: 'RBA',
      decimals: 18,
    },
    rpcUrls: NETWORK_RPC[CHAIN_ID.ROBURNA_TESTNET],
    blockExplorerUrls: [BLOCK_EXPLORER[CHAIN_ID.ROBURNA_TESTNET]],
  },
}
